// MEMU PRINCIPAL DOM SCRIPTING
const bodyWebsite = document.querySelector('body');
const headerWebsite = document.querySelector('header.header');
const btnMenuPrincipal = document.querySelector('#icon-menu-principal');
// ABRIR
$('#navbarMainMenu').on('show.bs.collapse', () => {
  bodyWebsite.classList.add('stop-scroll');
  headerWebsite.classList.add('active');
  btnMenuPrincipal.classList.remove('icon-menu','icon-transition');
  setTimeout(() => {
    btnMenuPrincipal.classList.add('icon-close-interaction','icon-transition');
  }, 100);
});
// CERRAR
$('#navbarMainMenu').on('hide.bs.collapse', () => {
  bodyWebsite.classList.remove('stop-scroll');
  headerWebsite.classList.remove('active');
  btnMenuPrincipal.classList.remove('icon-close-interaction','icon-transition');
  setTimeout(() => {
    btnMenuPrincipal.classList.add('icon-menu','icon-transition');
  }, 100);
});

const sectionForm = document.querySelector('#seccion-form');
const btnContacto = document.querySelector('#btnContacto');

const sectionFormNode = () => {
    if(sectionForm){
        btnContacto.setAttribute('href', '#ancla-seccion-form');
        return true;
    }
    else{
        btnContacto.setAttribute('href', 'contacto.php');
        return false;
    }
}
sectionFormNode();


btnContacto.addEventListener("click", () => {
    $('#navbarMainMenu').collapse('hide');
});

// function clickHandler (e){
//     if(sectionFormNode() === true){
//         e.preventDefault();
    
//         const href = this.getAttribute("href");
//         const offsetTop = document.querySelector(href).offsetTop;
    
//         scroll({
//             top: offsetTop,
//             behavior: "smooth"
//         });
//     }
// }